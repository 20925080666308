import BaseService from './base.service';
import { useAuthStore } from '../store/authStore';

class MemberService extends BaseService {
  async login(params) {
    const { signIn } = useAuthStore();

    const loginResult = await this.dao.login(params);
    const { code, payload } = loginResult;
    if (code === 0) {
      signIn(payload);
      return true;
    }
    return false;
  }

  async checklogin() {
    const { signIn, signOut } = useAuthStore();
    try {
      const checkLoginResult = await this.dao.checklogin();
      const { code, payload } = checkLoginResult;
      if (code === 0) {
        signIn(payload);
      }
    } catch (ex) {
      signOut();
    }
  }

  async getDashboards() {
    const dashBoardResult = await this.dao.getDashboards();
    const { code, payload } = dashBoardResult;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async getListContent(params) {
    const contentListResult = await this.dao.getListContent(params);
    const { code, payload } = contentListResult;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async getContentById(id) {
    const contentResult = await this.dao.getContentById(id);
    const { code, payload } = contentResult;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async getListTag() {
    const tagResult = await this.dao.getListTag();
    const { code, payload } = tagResult;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async getSuggestList() {
    const suggestResult = await this.dao.getSuggestList();
    const { code, payload } = suggestResult;
    if (code === 0) {
      return payload;
    }
    return {};
  }

  async updateUser(params) {
    const userResult = await this.dao.updateUser(params);
    const { code } = userResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async deleteReport(id) {
    const deleteResults = await this.dao.deleteReport(id);
    const { code } = deleteResults;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async updateViewMember(id, params) {
    const viewResult = await this.dao.updateViewMember(id, params);
    const { code } = viewResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async updateCompleteMember(id, params) {
    const viewResult = await this.dao.updateCompleteMember(id, params);
    const { code } = viewResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async getNotices() {
    const noticesResult = await this.dao.getNotices();
    const { code, payload } = noticesResult;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async getNotice(companyId, Id) {
    const noticeResult = await this.dao.getNotice(companyId, Id);
    const { code, payload } = noticeResult;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async getReport(id) {
    const reportResult = await this.dao.getReport(id);
    const { code, payload } = reportResult;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async updateForgetPassword(params) {
    const result = await this.dao.forgetPassword(params);
    const { code, payload } = result;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async getUserSetting() {
    const resultUserSetting = await this.dao.getUserSetting();
    const { code, payload } = resultUserSetting;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async getSetting() {
    const resultSetting = await this.dao.getSetting();
    const { code, payload } = resultSetting;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async updateReport(id, params) {
    const reportResult = await this.dao.updateReport(id, params);
    const { code, payload } = reportResult;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async createReport(params) {
    const reportResult = await this.dao.createReport(params);
    const { code, payload } = reportResult;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async updateUserSetting(params) {
    const resultSetting = await this.dao.updateUserSetting(params);
    const { code, payload } = resultSetting;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async confirmMail(data) {
    const result = await this.dao.confirmMail(data);
    return result;
  }

  async logout() {
    const resultlogout = await this.dao.logout();
    const { code, payload } = resultlogout;
    if (code === 0) {
      return true;
    }
    return false;
  }
}

export default new MemberService('member');
