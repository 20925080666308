import BaseRepository from './base.repository';

class UsersRepository extends BaseRepository {
  constructor() {
    super('/member');
  }

  find(params) {
    return this.client.get('', params);
  }

  login(params) {
    return this.client.post('/login', params);
  }

  checklogin() {
    return this.client.get('/checklogin');
  }

  getDashboards() {
    return this.client.get('/dashboards');
  }

  getListContent(params) {
    return this.client.get('/contents', params);
  }

  getContentById(id) {
    return this.client.get(`/contents/${id}`);
  }

  getListTag() {
    return this.client.get('/tags');
  }

  deleteReport(id) {
    return this.client.delete(`/reports/${id}`);
  }

  getSuggestList() {
    return this.client.get('/suggest-title');
  }

  updateUser(params) {
    return this.client.put('/users', params);
  }

  updateViewMember(id, params) {
    return this.client.post(`/contents/${id}/viewed`, params);
  }

  updateCompleteMember(id, params) {
    return this.client.post(`/contents/${id}/complete`, params);
  }

  getNotices() {
    return this.client.get('/notices');
  }

  getNotice(companyId, id) {
    return this.client.get(`/notices/${companyId}/${id}`);
  }

  forgetPassword(params) {
    return this.client.post('/forget-password', params);
  }

  getUserSetting() {
    return this.client.get('/users/setting');
  }

  getSetting() {
    return this.client.get('/setting');
  }

  getReport(id) {
    return this.client.get(`/reports/${id}`);
  }

  updateReport(id, params) {
    return this.client.put(`/reports/${id}`, params);
  }

  createReport(params) {
    return this.client.post('/reports', params);
  }

  updateUserSetting(params) {
    return this.client.put('/users/setting', params);
  }

  confirmMail(data) {
    return this.client.post('/users/confirm-mail', data);
  }

  logout() {
    return this.client.post('/logout', {});
  }
}

export default new UsersRepository();
